
import { Inject } from 'inversify-props';
import { Component, Vue } from 'vue-property-decorator';
import LocationAvailabilityToggle from './location-availability-toggle.vue';
import LocationAvailabilityCountryFilter from './actions/location-availability-country-filter.vue';
import LocationAvailabilityLokFilter from './actions/location-availability-lok-filter.vue';
import LocationAvailabilityPickUpCityFilter from './actions/location-availability-locations-filter.vue';
import LocationAvailabilityPosFilter from './actions/location-availability-pos-filter.vue';
import LocationAvailabilityProviderFilter from './actions/location-availability-provider-filter.vue';
import LocationAvailabilityFiltersService, { LocationAvailabilityFiltersServiceS } from '../location-availability-filters.service';

@Component({
    components: {
        LocationAvailabilityCountryFilter,
        LocationAvailabilityLokFilter,
        LocationAvailabilityPickUpCityFilter,
        LocationAvailabilityPosFilter,
        LocationAvailabilityProviderFilter,
        LocationAvailabilityToggle,
    },
})
export default class LocationAvailabilityActions extends Vue {
    @Inject(LocationAvailabilityFiltersServiceS) private lAvailabilityFiltersService!: LocationAvailabilityFiltersService;
}
