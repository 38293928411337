
import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
// @ts-ignore
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import CarsSharedService, { CarsSharedServiceS } from '@/modules/cars/cars-shared.service';
import LocationAvailabilityFiltersService, { LocationAvailabilityFiltersServiceS } from '../../location-availability-filters.service';

@Component({
    components: { CustomSelect },
})
export default class LocationsPosFilter extends Vue {
    @Inject(LocationAvailabilityFiltersServiceS) private lAvailabilityFiltersService!: LocationAvailabilityFiltersService;
    @Inject(CarsSharedServiceS) private carsSharedService!: CarsSharedService;

    get items(): Item[] {
        const { availablePos } = this.lAvailabilityFiltersService;

        return availablePos.map(value => ({
            name: value,
            value,
        }));
    }

    get currentValue() {
        return this.lAvailabilityFiltersService.pos;
    }
    set currentValue(value) {
        if (value) {
            this.carsSharedService.savePos(value);
        }
    }
}
